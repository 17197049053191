.primary-button {
  @apply outline-button;

  background-color: theme('colors.primary');
  color: theme('colors.white');
  border: none;

  &:hover:not(:disabled) {
    background: theme('colors.button.primary.hover');
  }

  &:active:not(:disabled) {
    background: theme('colors.button.primary.active');
  }
}
