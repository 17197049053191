@use './button' as base;

.outline-button {
  @include base.button;
  border-width: 1px;
  border-style: solid;
  border-color: theme('colors.black');
  color: theme('colors.black');

  &.secondary {
    border-color: theme('colors.white');
    color: theme('colors.white');
  }

  &:hover:not(:disabled) {
    background-color: theme('colors.button.outline.hover');
  }

  &:active:not(:disabled) {
    background-color: theme('colors.button.outline.active');
    @apply translate-x-0.5 translate-y-0.5;
  }

  &:disabled {
    background-color: theme('colors.button.disabled');
    border-color: theme('colors.button.disabled');
    color: theme('colors.form-field.text-disabled');
  }
}
