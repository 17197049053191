@mixin button {
  $button-height: 38px;
  $button-padding: 6px 16px;
  $button-min-width: 124px;

  appearance: none;
  text-decoration: none;
  min-height: $button-height;
  display: inline-grid;
  font-weight: 900;
  text-align: center;
  font-size: theme('fontSize.lg');
  border-radius: theme('borderRadius.DEFAULT');
  line-height: 1;
  align-items: center;
  justify-content: center;
  padding: $button-padding;
  min-width: $button-min-width;

  &:active:not(:disabled) {
    @apply translate-x-0.5 translate-y-0.5;
  }
}
