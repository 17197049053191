@import '@angular/cdk/overlay-prebuilt.css';

@import './components/button';
@import './components/checkbox/';
@import './components/form-fields/form-label/';

@font-face {
  font-family: 'Tenso';
  src: url('/assets/fonts/Tenso-Regular.ttf');
}

@font-face {
  font-family: 'Tenso';
  src: url('/assets/fonts/Tenso-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Tenso';
  src: url('/assets/fonts/Tenso-Black.ttf');
  font-weight: 900;
}

body {
  margin: 0;
}

@media (orientation: portrait) {
  body {
    background-color: theme('colors.primary');
  }
}

@media (orientation: landscape) {
  body {
    background-color: theme('colors.page.background');
  }
}

.page {
  @apply container mx-auto max-w-screen-2xl bg-page-background text-page-foreground;
}

input {
  &:disabled {
    color: theme('colors.form-field.text-disabled');
  }
}

app-form-dropdown-field > button {
  &:disabled {
    color: theme('colors.form-field.text-disabled');
  }
}
