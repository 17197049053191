.white-button {
  @apply outline-button;

  background-color: theme('colors.white');
  color: theme('colors.primary');
  border-color: theme('colors.white');

  &:hover:not(:disabled) {
    background: theme('colors.button.primary.hover');
    color: theme('colors.white');
  }

  &:active:not(:disabled) {
    background: theme('colors.button.primary.active');
  }
}
