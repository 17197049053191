@use './button' as base;

.icon-button {
  @include base.button;
  min-width: 0;
  background-color: transparent;
  color: theme('colors.white');

  &:active:not(:disabled) {
    background-color: inherit;
    transform: none;
  }

  &:hover:not(:disabled) {
    background-color: inherit;
  }

  &:disabled {
    background-color: inherit;
    color: theme('colors.form-field.icon-disabled');
  }
}
