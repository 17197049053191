.link-button {
  @apply outline-button;

  background-color: theme('colors.white');
  color: theme('colors.button.link.color');
  border: none;
  text-decoration: underline;

  &:hover:not(:disabled) {
    background-color: rgba(#000, 0.1);
    color: theme('colors.primary-lighter');
    text-decoration: none;
  }

  &:active:not(:disabled) {
    color: theme('colors.primary');
    text-decoration: none;
  }
}
