@use './button' as base;

.filled-button {
  @include base.button;

  background-color: theme('colors.primary');
  color: theme('colors.white');

  &:hover:not(:disabled) {
    background: theme('colors.button.primary.hover');
  }

  &:active:not(:disabled) {
    background: theme('colors.button.primary.active');
    @apply translate-x-0.5 translate-y-0.5;
  }

  &.secondary {
    background-color: theme('colors.black');

    &:hover:not(:disabled) {
      background-color: theme('colors.button.filled.hover');
    }

    &:active:not(:disabled) {
      background-color: theme('colors.button.filled.active');
      @apply translate-x-0.5 translate-y-0.5;
    }
  }

  &.tertiary {
    background-color: theme('colors.button.filled.tertiary.background');

    &:hover:not(:disabled) {
      background-color: theme('colors.button.filled.tertiary.hover');
    }

    &:active:not(:disabled) {
      background-color: theme('colors.button.filled.tertiary.active');
      @apply translate-x-0.5 translate-y-0.5;
    }
  }

  &:disabled {
    background-color: theme('colors.button.disabled');
    border-color: theme('colors.button.disabled');
    color: theme('colors.form-field.text-disabled');
  }
}
