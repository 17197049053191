.checkbox {
  @apply appearance-none w-4 min-w-4 max-w-4 h-4 min-h-4 max-h-4 relative 
  border-checkbox-primary rounded-sm border-2 
  checked:bg-checkbox-primary focus:border-primary focus:outline-none 
  transition-colors duration-75 cursor-pointer;
}

.checkbox::after {
  content: '';
  position: absolute;
  inset: 0;
  background-image: url('/assets/images/check.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: scale(0);
  transition: transform 0.075s;
}

.checkbox:checked::after {
  transform: scale(1);
}
