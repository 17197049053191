.form-label {
  @apply text-xs;

  display: flex;
  gap: 0.25rem;
  margin-bottom: 0.25rem;
  color: theme('colors.form-field.label');
}

.form-field:has([required='true']) > label::after {
  content: '*';
}
